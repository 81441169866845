import * as Trix from "trix/dist/trix"
import { Controller } from "@hotwired/stimulus"
import { destroyUploadedFile, uploadFile } from "../helpers/upload_helper"
import { extractVideoId, previewUrl } from "../helpers/video_helper"

export default class extends Controller {
  static targets = ["editor", "embedCode", "colorsDialog", "emojiDialog"]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  declare readonly editorTarget: any
  declare readonly embedCodeTarget: HTMLTextAreaElement
  declare readonly colorsDialogTarget: HTMLElement
  declare readonly emojiDialogTarget: HTMLElement
  private typingTimer
  private doneTypingInterval = 2000

  acceptAttachment(e) {
    if (!this.data.has("uploadUrl")) {
      e.preventDefault()
    }
  }

  attachFile(e) {
    if (!e.attachment.file) {
      return
    }
    uploadFile(this.data.get("uploadUrl"), e.attachment)
  }

  addAttachment() {
    const fileInput = $("<input type='file' multiple>")
    const editor = this.editorTarget.editor
    fileInput.on("change", function (this: HTMLInputElement) {
      for (let i = 0, j = this.files.length; i < j; i++) {
        editor.insertFile(this.files[i])
      }
    })
    fileInput.click()
  }

  toggleColors() {
    if (typeof this.colorsDialogTarget.dataset.trixActive === "undefined") {
      this.showColors()
    } else {
      this.hideColors()
    }
  }

  showColors() {
    this.editorTarget.editorController.toolbarWillShowDialog(
      this.colorsDialogTarget,
    )
    this.colorsDialogTarget.dataset.trixActive = ""
    this.editorTarget.editorController.toolbarDidShowDialog("x-color")
  }

  hideColors() {
    delete this.colorsDialogTarget.dataset.trixActive
    this.editorTarget.editorController.toolbarDidHideDialog("x-color")
  }

  insertEmbed() {
    const url = this.embedCodeTarget.value
    const id = extractVideoId(url)
    if (id) {
      const attachment = new Trix.Attachment({
        previewable: true,
        href: url,
        externalId: id,
        url: previewUrl(url),
      })
      this.editorTarget.editor.insertAttachment(attachment)
      this.embedCodeTarget.value = ""
    }
  }

  insertEmoji(e) {
    this.editorTarget.editor.insertHTML(e.target.innerHTML)
    delete this.emojiDialogTarget.dataset.trixActive
    this.editorTarget.editorController.toolbarDidHideDialog({
      dialogName: "x-insert-emoji",
    })
    this.editorTarget.editor.moveCursorInDirection("forward")
  }

  removeFile(e) {
    destroyUploadedFile(e.attachment.getAttribute("removeUrl"))
  }

  toggleColorRed() {
    this.toggleStyle("color", "#e3342f")
  }

  toggleColorGreen() {
    this.toggleStyle("color", "#38c172")
  }

  toggleColorBlue() {
    this.toggleStyle("color", "#01AAFF")
  }

  toggleColorPurple() {
    this.toggleStyle("color", "#9561e2")
  }

  toggleColorYellow() {
    this.toggleStyle("color", "#ffed4a")
  }

  toggleBackgroundRed() {
    this.toggleStyle("backgroundColor", "#f9acaa")
  }

  toggleBackgroundGreen() {
    this.toggleStyle("backgroundColor", "#a2f5bf")
  }

  toggleBackgroundBlue() {
    this.toggleStyle("backgroundColor", "#bcdefa")
  }

  toggleBackgroundPurple() {
    this.toggleStyle("backgroundColor", "#d6bbfc")
  }

  toggleBackgroundYellow() {
    this.toggleStyle("backgroundColor", "#fff9c2")
  }

  removeColor() {
    this.editorTarget.editor.deactivateAttribute("color")
  }

  removeBackgroundColor() {
    this.editorTarget.editor.deactivateAttribute("backgroundColor")
  }

  toggleStyle(style, value) {
    const e = this.editorTarget.editor
    if (e.attributeIsActive(style)) {
      e.deactivateAttribute(style)
    } else {
      e.activateAttribute(style, value)
    }
    this.hideColors()
  }

  clearTypingTimeout() {
    clearTimeout(this.typingTimer)
  }

  setTypingTimeout() {
    clearTimeout(this.typingTimer)
    this.typingTimer = setTimeout(this.saveDraft, this.doneTypingInterval)

    this.enableSubmit()
  }

  enableSubmit() {
    const form = this.editorTarget.closest("form")
    if (form) {
      const submitButton = $(form).find("button[type='submit'], input[type='submit']")
      const body = $("trix-editor").text()
      
      if (submitButton && body && body.length > 1) {
        submitButton.removeAttr("disabled")
      }
      else {
        submitButton.attr("disabled", "disabled")
      }
    }
  }

  saveDraft() {
    const body = $("trix-editor").html()
    const saveDraftUrl = $("#message_composer").data("editor-save-draft-url")

    if (body == null || body == undefined || body.length == 0) {
      return
    }

    return jQuery
      .ajax({
        url: saveDraftUrl,
        method: "PATCH",
        data: { message: { body: body } },
      })
      .done(function () {
        $("#autosaved").removeClass("hidden")
      })
      .fail((e) => {
        console.error("Error when saving draft message", e)
      })
  }
}
